var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar"},[_c('div',{staticClass:"calendar-wrap"},[_c('KIconButton',{staticClass:"left",attrs:{"aria-label":_vm.previousMonthText,"tooltip":_vm.previousMonthText,"icon":"chevronLeft","appearance":"flat-button","size":"mini"},on:{"click":_vm.goPrevMonth}}),_vm._v(" "),_c('KIconButton',{staticClass:"right",attrs:{"aria-label":_vm.nextMonthText,"tooltip":_vm.nextMonthText,"icon":"chevronRight","appearance":"flat-button","size":"mini"},on:{"click":_vm.goNextMonth}}),_vm._v(" "),_c('div',{staticClass:"calendar-month-left"},[_c('div',{staticClass:"months-text",attrs:{"data-test":"previousMonth"}},[_vm._v("\n        "+_vm._s(_vm.monthString(_vm.activeMonth) + ' ' + _vm.activeYearStart)+"\n      ")]),_vm._v(" "),_vm._l((6),function(weekIndex){return _c('ul',{key:weekIndex,staticClass:"calendar-days"},_vm._l((_vm.numOfDays),function(dayInWeekIndex){return _c('li',{key:dayInWeekIndex,class:[
            {
              'calendar-days-disabled':
                _vm.isDateDisabled(weekIndex, dayInWeekIndex, _vm.activeMonthDay, _vm.activeMonthDate) ||
                _vm.isDateDisabledLeft(weekIndex, dayInWeekIndex, _vm.activeMonthDay),
              'selected-first':
                _vm.selectionOrder(
                  weekIndex,
                  dayInWeekIndex,
                  'first',
                  _vm.activeMonthDay,
                  _vm.activeMonthDate,
                ) === 'first',
              'selected-second':
                _vm.selectionOrder(
                  weekIndex,
                  dayInWeekIndex,
                  'first',
                  _vm.activeMonthDay,
                  _vm.activeMonthDate,
                ) === 'second',
            },
          ],style:([
            _vm.selectionOrder(
              weekIndex,
              dayInWeekIndex,
              'first',
              _vm.activeMonthDay,
              _vm.activeMonthDate,
            ) === 'first' ||
              _vm.selectionOrder(
                weekIndex,
                dayInWeekIndex,
                'first',
                _vm.activeMonthDay,
                _vm.activeMonthDate,
              ) === 'second'
              ? { backgroundColor: _vm.$themeBrand.primary.v_100 }
              : {},
          ]),on:{"click":function($event){return _vm.selectFirstItem(weekIndex, dayInWeekIndex)}}},[_c('KDateDay',{attrs:{"day":_vm.getDayCell(weekIndex, dayInWeekIndex, _vm.activeMonthDay, _vm.activeMonthDate),"isSelected":_vm.isDateSelected(weekIndex, dayInWeekIndex, 'first', _vm.activeMonthDay, _vm.activeMonthDate),"isInRange":_vm.isDateInRange(weekIndex, dayInWeekIndex, 'first', _vm.activeMonthDay, _vm.activeMonthDate),"isDisabled":_vm.isDateDisabled(weekIndex, dayInWeekIndex, _vm.activeMonthDay, _vm.activeMonthDate) ||
                _vm.isDateDisabledLeft(weekIndex, dayInWeekIndex, _vm.activeMonthDay),"isLastDay":_vm.isLastDay(weekIndex, dayInWeekIndex, 'first', _vm.activeMonthDay, _vm.activeMonthDate),"isEndOfWeek":dayInWeekIndex === 7,"isStartOfWeek":dayInWeekIndex === 1,"activeMonth":_vm.activeMonth}})],1)}),0)})],2),_vm._v(" "),_c('div',{staticClass:"calendar-month-right"},[_c('div',{staticClass:"months-text",attrs:{"data-test":"currentMonth"}},[_vm._v("\n        "+_vm._s(_vm.monthString(_vm.nextActiveMonth) + ' ' + _vm.activeYearEnd)+"\n      ")]),_vm._v(" "),_vm._l((6),function(weekIndex){return _c('ul',{key:weekIndex,staticClass:"calendar-days"},_vm._l((_vm.numOfDays),function(dayInWeekIndex){return _c('li',{key:dayInWeekIndex,class:[
            {
              'calendar-days-disabled':
                _vm.isDateDisabled(
                  weekIndex,
                  dayInWeekIndex,
                  _vm.nextActiveMonthDay,
                  _vm.nextActiveMonthDate,
                ) || _vm.isDateDisabledRight(weekIndex, dayInWeekIndex, _vm.nextActiveMonthDay),
              'selected-first':
                _vm.selectionOrder(
                  weekIndex,
                  dayInWeekIndex,
                  'second',
                  _vm.nextActiveMonthDay,
                  _vm.nextActiveMonthDate,
                ) === 'first',
              'selected-second':
                _vm.selectionOrder(
                  weekIndex,
                  dayInWeekIndex,
                  'second',
                  _vm.nextActiveMonthDay,
                  _vm.nextActiveMonthDate,
                ) === 'second',
            },
          ],style:([
            _vm.selectionOrder(
              weekIndex,
              dayInWeekIndex,
              'second',
              _vm.nextActiveMonthDay,
              _vm.nextActiveMonthDate,
            ) === 'first' ||
              _vm.selectionOrder(
                weekIndex,
                dayInWeekIndex,
                'second',
                _vm.nextActiveMonthDay,
                _vm.nextActiveMonthDate,
              ) === 'second'
              ? { backgroundColor: _vm.$themeBrand.primary.v_100 }
              : {},
          ]),on:{"click":function($event){return _vm.selectSecondItem(weekIndex, dayInWeekIndex)}}},[_c('KDateDay',{attrs:{"day":_vm.getDayCell(weekIndex, dayInWeekIndex, _vm.nextActiveMonthDay, _vm.nextActiveMonthDate),"isSelected":_vm.isDateSelected(
                weekIndex,
                dayInWeekIndex,
                'second',
                _vm.nextActiveMonthDay,
                _vm.nextActiveMonthDate,
              ),"isInRange":_vm.isDateInRange(
                weekIndex,
                dayInWeekIndex,
                'second',
                _vm.nextActiveMonthDay,
                _vm.nextActiveMonthDate,
              ),"isDisabled":_vm.isDateDisabled(
                weekIndex,
                dayInWeekIndex,
                _vm.nextActiveMonthDay,
                _vm.nextActiveMonthDate,
              ) || _vm.isDateDisabledRight(weekIndex, dayInWeekIndex, _vm.nextActiveMonthDay),"isLastDay":_vm.isLastDay(
                weekIndex,
                dayInWeekIndex,
                'second',
                _vm.nextActiveMonthDay,
                _vm.nextActiveMonthDate,
              ),"isEndOfWeek":dayInWeekIndex === 7,"isStartOfWeek":dayInWeekIndex === 1,"activeMonth":_vm.nextActiveMonth}})],1)}),0)})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }