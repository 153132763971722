<template>

  <DocsPageTemplate>
    <DocsPageSection
      title="Overview"
      anchor="#overview"
    >
      <p>
        Menus allow users to make a selection from a list of options. We use two types of menus:
        <em>Dropdown</em> and <em>Filter</em>.
      </p>

      <h3>Dropdown menu</h3>
      <p>
        Allow users to discover and access complex actions. They can be generated from buttons,
        icons, and in some cases by right-clicking a location.
      </p>

      <DocsShow>
        <img
          class="modal-img"
          src="./dropdown-menu.png"
        >
      </DocsShow>

      <h3>Filter menu</h3>
      <p>
        Filter menus allow users to filter information by selecting from a long list of options.
        They can be generated by clicking a text field.
      </p>

      <DocsShow>
        <img
          class="modal-img"
          src="./filter-menu.png"
        >
      </DocsShow>
    </DocsPageSection>

    <DocsPageSection
      title="Menu text"
      anchor="#menu-text"
    >
      <ul>
        <li>Menu options can be user-generated text</li>
        <li>Menu text is 2-3 words by default</li>
        <li>Menu option text may have a disabled style</li>
      </ul>
    </DocsPageSection>

    <DocsDoNot>
      <template #do>
        <img src="./text-do.png" >
        <p class="do-dont">Minimize text and rely on interface clarity</p>
      </template>
      <template #not>
        <img src="./text-dont.png" >
        <p class="do-dont">Write repetitive menu option text</p>
      </template>
    </DocsDoNot>

    <DocsPageSection
      title="Layout"
      anchor="#layout"
    >
      <ul>
        <li>Menus appear above all other UI elements</li>
        <li>Menus appear directly below the element that generated it</li>
        <li>Menus do not move from their original location when scrolling through the page</li>
        <li>Menus should never touch the edge of the browser screen. Apply an offset of 8px.</li>
      </ul>
    </DocsPageSection>

    <DocsPageSection
      title="Guidelines"
      anchor="#guidelines"
    >
      <ul>
        <li>Menus can contain icons</li>
        <li>Menus can have dividers to group relevant options together</li>
        <li>By default only one menu item can be selected at a time</li>
        <li>Multiple menu options can be selected in special cases</li>
      </ul>
    </DocsPageSection>

    <DocsDoNot>
      <template #do>
        <img src="./icon-do.png" >
        <p class="do-dont">Use iconography as a visual aid for important actions</p>
      </template>
      <template #not>
        <img src="./icon-dont.png" >
        <p class="do-dont">Use repetitive iconography in filter menus</p>
      </template>
    </DocsDoNot>

    <DocsPageSection
      title="Specifications"
      anchor="#specifications"
    >
      <ul>
        <li>Min width: 128px</li>
        <li>Menu elevation: 4dp</li>
        <li>
          Background color:
          <DocsInternalLink
            code
            text="tokens.surface"
            href="/colors#palette.white"
          />
        </li>
        <li>
          Option text color:
          <DocsInternalLink
            code
            text="tokens.text"
            href="/colors#palette-grey-v_200"
          />
        </li>
        <li>
          Option text hover color:
          <DocsInternalLink
            code
            text="palette.grey.v_200"
            href="/colors#palette-grey-v_200"
          />
        </li>
        <li>
          Iconography color:
          <DocsInternalLink
            code
            text="palette.grey.v_800"
            href="/colors#palette-grey-v_800"
          />
        </li>
      </ul>
      <h3>Dropdown menu</h3>
      <ul>
        <li>Width: matches the width of the longest text option</li>
        <li>Menu option height: 32px</li>
      </ul>
      <h3>Filter menu</h3>
      <ul>
        <li>Width: width: matches the width of the text field that generates it</li>
        <li>Menu option height: 48px</li>
      </ul>
    </DocsPageSection>
  </DocsPageTemplate>

</template>


<script>

  export default {};

</script>


<style lang="scss" scoped>

  img,
  .do-dont {
    width: 400px;
  }

</style>
