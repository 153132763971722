var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":_vm.disableDefaultTransition ? '' : 'ui-progress-circular--transition-fade'}},[(_vm.show(_vm._uid, _vm.shouldShow, _vm.minVisibleTime))?_c('div',{staticClass:"ui-progress-circular",class:[_vm.classes, { delay: _vm.delay }],style:({ width: _vm.size + 'px', height: _vm.size + 'px' })},[(_vm.type === 'determinate')?_c('svg',{staticClass:"ui-progress-circular-determinate",attrs:{"role":"progressbar","aria-valuemax":100,"aria-valuemin":0,"aria-valuenow":_vm.progress,"height":_vm.size,"width":_vm.size}},[_c('circle',{staticClass:"ui-progress-circular-determinate-path",style:({
          'stroke-dashoffset': _vm.strokeDashOffset,
          'stroke-width': _vm.calculatedStroke,
          stroke: _vm.$themeTokens.loading,
        }),attrs:{"fill":"transparent","stroke-dashoffset":"0","cx":_vm.size / 2,"cy":_vm.size / 2,"r":_vm.radius,"stroke-dasharray":_vm.strokeDashArray}})]):_c('svg',{staticClass:"ui-progress-circular-indeterminate",style:({ stroke: _vm.$themeTokens.loading }),attrs:{"role":"progressbar","viewBox":"25 25 50 50","aria-valuemax":100,"aria-valuemin":0}},[_c('circle',{staticClass:"ui-progress-circular-indeterminate-path",style:({
          stroke: _vm.$themeTokens.loading,
        }),attrs:{"cx":"50","cy":"50","fill":"none","r":"20","stroke-miterlimit":"10","stroke-width":_vm.calculatedStroke}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }