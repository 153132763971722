<template>

  <DocsPageTemplate apiDocs>
    <DocsPageSection
      title="Overview"
      anchor="#overview"
    >
      <p>
        Grid layout with a fixed number of columns. Use with
        <DocsLibraryLink component="KFixedGridItem" />.
      </p>
      <p>
        For a responsive grid with a variable number of columns based on screen size, see
        <DocsLibraryLink component="KGrid" /> and <DocsLibraryLink component="KGridItem" />.
      </p>
    </DocsPageSection>
  </DocsPageTemplate>

</template>


<script>

  export default {};

</script>


<style lang="scss" scoped></style>
