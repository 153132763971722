var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KButton',{class:[
    {
      'calendar-days-selected': _vm.isSelected,
      'calendar-days-in-range': _vm.isInRange,
      'end-of-week': _vm.isEndOfWeek,
      'start-of-week': _vm.isStartOfWeek,
      'first-day': _vm.isFirstDay,
      'last-day': _vm.isLastDay,
    },
  ],style:([_vm.inRangeStyle, _vm.selectedStyle]),attrs:{"autocomplete":"off","primary":false,"appearance":"flat-button","appearanceOverrides":_vm.styleOverrides,"disabled":_vm.isDisabled,"aria-hidden":_vm.isDisabled}},[_vm._v("\n  "+_vm._s(_vm.day)+"\n  "),_c('span',{staticClass:"k-date-vhidden",attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.day))]),_vm._v(" "),_c('span',{staticClass:"k-date-vhidden",attrs:{"aria-hidden":_vm.isDisabled}},[_vm._v(_vm._s(_vm.toMonthName(_vm.activeMonth)))])])
}
var staticRenderFns = []

export { render, staticRenderFns }