<template>

  <DocsPageTemplate apiDocs>
    <DocsPageSection
      title="Overview"
      anchor="#overview"
    >
      <p>
        A part of a tabbed interface that displays content associated with the active tab. It is
        meant to be used together with <DocsLibraryLink component="KTabsList" />. Please study
        <DocsLibraryLink component="KTabsList" /> page to understand how to use
        <code>KTabsPanel</code>.
      </p>
    </DocsPageSection>

    <DocsPageSection
      title="Related"
      anchor="#related"
    >
      <ul>
        <li>
          <DocsInternalLink
            text="Tabs page"
            href="/tabs"
          />
          has an overview and usage guidance for all tab-related components
        </li>
        <li>
          <DocsLibraryLink component="KTabsList" /> is a component to be used together with
          <code>KTabsPanel</code> and contains detailed guidance on using both these components
        </li>
        <li><DocsLibraryLink component="KTabs" /> is an alternative way to implement tabs</li>
      </ul>
    </DocsPageSection>
  </DocsPageTemplate>

</template>


<script>

  export default {};

</script>


<style lang="scss" scoped></style>
