<template>

  <DocsPageTemplate apiDocs>
    <DocsPageSection
      title="Overview"
      anchor="#overview"
    >
      See
      <DocsInternalLink
        href="/icons"
        text="the page on icons"
      />
      for design guidance and a list of available icons. If an invalid icon is used, in development
      Vue.js validation will warn about the error. The icon will display as a broken image
      <KIcon icon="brokenImage" /> icon.
    </DocsPageSection>
  </DocsPageTemplate>

</template>


<script>

  export default {};

</script>


<style lang="scss" scoped></style>
