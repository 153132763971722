var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('KTabsList',_vm._b({scopedSlots:_vm._u([{key:"tab",fn:function({ tab, isActive }){return [_vm._t("tab",null,{"tab":tab,"isActive":isActive})]}}],null,true),model:{value:(_vm.activeTabId),callback:function ($$v) {_vm.activeTabId=$$v},expression:"activeTabId"}},'KTabsList',{
      tabsId: _vm.tabsId,
      tabs: _vm.tabs,
      ariaLabel: _vm.ariaLabel,
      ariaLabelledBy: _vm.ariaLabelledBy,
      color: _vm.color,
      colorActive: _vm.colorActive,
      backgroundColor: _vm.backgroundColor,
      hoverBackgroundColor: _vm.hoverBackgroundColor,
      appearanceOverrides: _vm.appearanceOverrides,
      appearanceOverridesActive: _vm.appearanceOverridesActive,
      enablePrint: _vm.enablePrint,
    },false)),_vm._v(" "),_c('KTabsPanel',{style:({ margin: '16px 0' }),attrs:{"tabsId":_vm.tabsId,"activeTabId":_vm.activeTabId},scopedSlots:_vm._u([_vm._l((_vm.$slots),function(_,slot){return {key:slot,fn:function(){return [_vm._t(slot)]},proxy:true}})],null,true)},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }