var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DocsPageTemplate',{attrs:{"apiDocs":""}},[_c('DocsPageSection',{attrs:{"title":"Overview","anchor":"#overview"}},[_c('div',[_vm._v("\n      Breadcrumbs help understand the hierarchy among levels and a user's location in it. We often\n      use breadcrumbs to aid navigation across channels, topic trees, and resources.\n\n      "),_c('DocsShow',{attrs:{"block":""}},[_c('KBreadcrumbs',{attrs:{"items":[
            { text: 'Global Digital Library', link: { path: '#' } },
            { text: 'English', link: { path: '#' } },
            { text: 'Level 2 ', link: { path: '#' } },
          ]}})],1)],1),_vm._v(" "),_c('div',[_vm._v("\n      Breadcrumbs take the full width of their container. When there is not enough space\n      available, items that can't be displayed will automatically collapse into the drop-down and\n      visible items might also get truncated:\n\n      "),_c('DocsShow',{style:({ maxWidth: '500px' }),attrs:{"block":""}},[_c('KBreadcrumbs',{attrs:{"items":[
            { text: 'Global Digital Library', link: { path: '#' } },
            { text: 'English', link: { path: '#' } },
            { text: 'Level 2 ', link: { path: '#' } },
            { text: 'Kaka and Munni: A Folktale from Punjab', link: { path: '#' } },
          ]}})],1)],1),_vm._v(" "),_c('div',[_vm._v("\n      When there is only one item, it won't be displayed by default. You can set\n      "),_c('code',[_vm._v("showSingleItem")]),_vm._v(" to "),_c('code',[_vm._v("true")]),_vm._v(" to show it:\n\n      "),_c('DocsShow',{attrs:{"block":""}},[_c('KBreadcrumbs',{attrs:{"items":[{ text: 'Global Digital Library', link: { path: '#' } }],"showSingleItem":""}})],1)],1),_vm._v(" "),_c('div',[_vm._v("\n      Links to intermediary items can be disabled by omitting the "),_c('code',[_vm._v("link")]),_vm._v(" attribute, or\n      making it falsey.\n\n      "),_c('DocsShow',{attrs:{"block":""}},[_c('KBreadcrumbs',{attrs:{"items":[
            { text: 'Global Digital Library', link: { path: '#' } },
            { text: 'English' },
            { text: 'Reading ', link: { path: '#' } },
            { text: 'Level 2 ', link: { path: '#' } },
          ]}})],1)],1)]),_vm._v(" "),_c('DocsPageSection',{attrs:{"title":"Placement","anchor":"#placement"}},[_c('ul',[_c('li',[_vm._v("Directly above the content to be navigated through")])])]),_vm._v(" "),_c('DocsPageSection',{attrs:{"title":"Guidelines","anchor":"#guidelines"}},[_c('ul',[_c('li',[_vm._v("It should include the current item")]),_vm._v(" "),_c('li',[_vm._v("The current item can be repeated in a page header")]),_vm._v(" "),_c('li',[_vm._v("\n        Allow the single breadcrumb ("),_c('code',[_vm._v("showSingleItem=true")]),_vm._v(") only when there isn't any\n        other page header or label that shows what the current level is\n      ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }